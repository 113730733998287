import { Link } from '@remix-run/react';
import * as React from 'react';
import { cn } from '#/app/utils/misc.tsx';
import { Container } from '#app/components/container.tsx';
import { Logo } from '#app/components/logo.tsx';
import { Button } from '#app/components/ui/button.tsx';
import { Icon } from '#app/components/ui/icon.tsx';
import { analytics } from '#app/utils/segment.ts';
import { type IconName } from '@/icon-name';

export function Header({
	panelId,
	icon,
	expanded,
	onToggle,
	toggleRef,
	invert = false,
}: {
	panelId: string;
	icon: IconName;
	expanded: boolean;
	onToggle: () => void;
	toggleRef: React.RefObject<HTMLButtonElement>;
	invert?: boolean;
}) {
	return (
		<Container>
			<div className="flex items-center justify-between">
				<Link to="/" aria-label="Home">
					<Logo
						className={cn('h-11', {
							'fill-neutral': invert,
						})}
					/>
				</Link>
				<div className="flex items-center gap-x-8">
					<Button asChild>
						<Link
							onClick={() => {
								analytics.track('Contact Us Clicked', {
									location: 'Header',
								});
							}}
							to="mailto:hello@upbeat.works"
						>
							Contact us!
						</Link>
					</Button>
					<button
						ref={toggleRef}
						type="button"
						onClick={() => {
							analytics.track('Menu Toggled');
							onToggle();
						}}
						aria-expanded={expanded ? 'true' : 'false'}
						aria-controls={panelId}
						className={cn(
							'group -m-2.5 rounded-full p-2.5 transition',
							invert && 'hover:bg-primary-hover',
						)}
						aria-label="Toggle navigation"
					>
						<Icon
							name={icon}
							className={cn(
								'h-6 w-6',
								invert
									? 'text-primary-light group-hover:text-primary-light-hover'
									: 'text-neutral-darker group-hover:text-neutral-dark-hover',
							)}
						/>
					</button>
				</div>
			</div>
		</Container>
	);
}
