import { Link } from '@remix-run/react';
import { Container } from '#app/components/container.tsx';
import { FadeIn } from '#app/components/fade-in.tsx';
import { LogoAvatar } from '#app/components/logo.tsx';
import { socialMediaProfiles } from '#app/components/social-media.tsx';
import { analytics } from '#app/utils/segment.ts';

const navigation: {
	title: string;
	links: {
		title: string | JSX.Element;
		href: string;
		onClick?: () => void;
	}[];
}[] = [
	// {
	//   title: 'Work',
	//   links: [
	//     { title: 'FamilyFund', href: '/work/family-fund' },
	//     { title: 'Unseal', href: '/work/unseal' },
	//     { title: 'Phobia', href: '/work/phobia' },
	//     {
	//       title: (
	//         <>
	//           See all <span aria-hidden="true">&rarr;</span>
	//         </>
	//       ),
	//       href: '/work',
	//     },
	//   ],
	// },
	{
		title: 'Company',
		links: [
			// { title: 'About', href: '/about' },
			// { title: 'Process', href: '/process' },
			// { title: 'Blog', href: '/blog' },
			{
				title: 'Contact us',
				href: 'mailto:hello@upbeat.works',
				onClick: () =>
					analytics.track('Contact Us Clicked', { location: 'Footer' }),
			},
		],
	},
	{
		title: 'Connect',
		links: socialMediaProfiles,
	},
];

export function Footer() {
	return (
		<Container as="footer" className="mt-24 w-full sm:mt-32 lg:mt-40">
			<FadeIn>
				<div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
					<Navigation />
				</div>
				<div className="mb-20 mt-24 flex flex-wrap items-end justify-between gap-x-6 gap-y-4 border-t border-primary-darker/10 pt-12">
					<Link to="/" aria-label="Home">
						<LogoAvatar className="h-12 w-12" />
					</Link>
					<p className="text-sm text-primary-darker">
						© Upbeat, SL. {new Date().getFullYear()}
					</p>
				</div>
			</FadeIn>
		</Container>
	);
}

function Navigation() {
	return (
		<nav>
			<ul className="grid grid-cols-2 gap-8 sm:grid-cols-3">
				{navigation.map((section, sectionIndex) => (
					<li key={sectionIndex}>
						<div className="font-display text-sm font-semibold tracking-wider text-primary-darker">
							{section.title}
						</div>
						<ul className="mt-4 text-sm text-primary-darker">
							{section.links.map((link, linkIndex) => (
								<li key={linkIndex} className="mt-4">
									<Link
										to={link.href}
										onClick={link.onClick}
										className="transition hover:text-primary-darker"
									>
										{link.title}
									</Link>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</nav>
	);
}
